import { cn } from '@zep/utils';
import { zepAnalytics } from '@zep/utils';
import { useTranslation } from 'next-i18next';

import styles from './Footer.module.scss';

export type FooterClassNameProps = {
  containerClassName?: string;
};

export default function Footer(props: FooterClassNameProps) {
  const { t } = useTranslation();

  return (
    <footer className={cn(styles.footer_container, props.containerClassName)}>
      <div className={styles.main_info_container}>
        <img
          className={styles.logo_img}
          src={'/assets/ZEPQuiz-logo.svg'}
          alt="zepquiz"
        />
        <img
          className={styles.logo_small}
          src={'/assets/logo_small_q.svg'}
          alt="logo"
        />
        <div className={styles.menus_wrapper}>
          <a
            href={t('common.account.termsUrl')}
            target="_blank"
            onClick={() => {
              zepAnalytics.track('enter_terms', { location: 'footer' });
            }}
            className={styles.menu_item}>
            {t('global.terms')}
          </a>
          <div className={styles.menu_divider}></div>
          <a
            href={t('common.account.privacyUrl')}
            onClick={() => {
              zepAnalytics.track('enter_privacy', { location: 'footer' });
            }}
            target="_blank"
            className={styles.menu_item}>
            {t('global.privacy')}
          </a>
        </div>
      </div>
      <div className={styles.sub_info_container}>{t('footer.value')}</div>
    </footer>
  );
}
