import { ComponentPropsWithoutRef } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';

import Footer from '../components/Footer.tsx';
import { CodeEnter } from '../components/modals/CodeEnter.tsx';
import { useViewportDevice } from '../hooks';
import styles from '../pages/Home.module.scss';
import { cn, zepAnalytics } from '../utils';

export const Landing = () => {
  return (
    <>
      <div className={styles.home_container}>
        <div className={'pb- flex flex-col gap-[40px] pb-[40px] lg:pb-[90px]'}>
          <div
            className={
              'mx-auto flex w-[90%] max-w-[960px] flex-col items-center justify-center'
            }>
            <CTA />
            <LandingVideo />
            <Features />
          </div>
          <GoogleEducation />
        </div>
      </div>
      <Footer />
    </>
  );
};

const CTA = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.home_title_text}>
        <span className="text-gray-600">{t('landing.title.first')}</span>
        <span className="font-black text-primary">
          {t('landing.title.highlight')}
        </span>
        <span className="text-gray-600">{t('landing.title.second')}</span>
      </div>
      <div className={'flex gap-sm'}>
        <div className={styles.button_wrapper}>
          <Link
            prefetch={false}
            href={'/build'}
            onClick={() =>
              zepAnalytics.track('enter_create', {
                location: 'home',
              })
            }>
            <div className={styles.home_cta_button}>
              <img
                className={styles.icon_plus_circle}
                src={'/assets/icon_plus_circle.png'}
                alt="plus"
              />
              <div>{t('landing.create')}</div>
            </div>
          </Link>
        </div>
        <div>
          <div
            className={styles.home_enter_button}
            onClick={() => {
              NiceModal.show(CodeEnter);
              zepAnalytics.track('enter_code', {
                location: 'landing',
              });
            }}>
            <img
              className={styles.icon_plus_circle}
              src={'/assets/icon_enter_green.svg'}
              alt="plus"
            />
            <div>{t('landing.enterCode')}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const LandingVideo = () => {
  const { locale = 'en' } = useRouter();

  return (
    <video
      className={'mb-[40px] mt-[58px] w-full max-w-[960px] rounded-[8px]'}
      playsInline
      controls={false}
      autoPlay
      muted
      loop>
      <source
        src={landingVideo['webm'][locale] || landingVideo['webm']['en']}
        type="video/webm"
      />
      <source
        type="video/mp4"
        src={landingVideo['mp4'][locale] || landingVideo['mp4']['en']}
      />
      Your browser does not support the video tag.
    </video>
  );
};

const Features = () => {
  const { t } = useTranslation();
  const { locale = 'ko' } = useRouter();

  const indexes: Record<string, string>[] = [
    {
      ko: '/assets/home_card_1.png',
      ja: '/assets/home_card_1_ja.png',
      en: '/assets/home_card_1_en.png',
      title: t('landing.card1.title'),
      description: t('landing.card1.description'),
    },
    {
      ko: '/assets/home_card_2.png',
      ja: '/assets/home_card_2_ja.png',
      en: '/assets/home_card_2_en.png',
      title: t('landing.card2.title'),
      description: t('landing.card2.description'),
    },
  ];

  return (
    <div className={styles.home_card_container}>
      {indexes.map((obj, index) => (
        <div key={index} className={styles.home_card}>
          <div className={'flex flex-col gap-md'}>
            <div
              className={
                'text-center text-[30px] font-extrabold leading-normal text-gray-700'
              }>
              {obj.title}
            </div>
            <div
              className={
                'whitespace-pre-wrap px-1.5  text-center text-body-lg font-semibold text-gray-700'
              }>
              {obj.description}
            </div>
          </div>

          <div className={styles.home_card_image_container}>
            <img
              className={styles.home_card_image}
              src={obj[locale] || obj['en']}
              alt="home_card"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const GoogleEducation = () => {
  const { t } = useTranslation(['common']);
  const { isPortableDevice } = useViewportDevice();

  return (
    <div className={'flex w-full flex-col bg-gray-50'}>
      <div
        className={cn([
          'mx-auto flex items-center gap-[24px] lg:px-0 py-[80px] text-center',
          'px-[32px] flex-col',
        ])}>
        <div>
          <img
            src={
              !isPortableDevice
                ? '/assets/google-zep-logo.svg'
                : '/assets/google-zep-logo-mobile.svg'
            }
            alt="logo"
          />
        </div>
        <span
          className={'text-[16px] font-medium leading-normal text-gray-600'}>
          <Trans
            t={t}
            i18nKey={'common:landing.googleLogo.description'}
            components={{
              Comp: <BoldText text={t('common:landing.googleLogo.accent')} />,
            }}
          />
        </span>
      </div>
    </div>
  );
};

const BoldText = (
  props: ComponentPropsWithoutRef<'div'> & { text: string },
) => {
  return (
    <span className={'font-bold text-gray-700'} {...props}>
      {props.text}
    </span>
  );
};

const landingVideo: {
  webm: Record<string, string>;
  mp4: Record<string, string>;
} = {
  webm: {
    ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/EfYNn3wK87cudXoAHwEbXPj/kDRvy3wK87h9d357d4b149f890789bef39bb4a72f27udXsAydz1WjZ/zep_quiz_landing.webm',
    en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/PXx4H3wK87dudXoAHxsE5ka/Dyj7X3wK87h219088316eebd30c1f2c79aa4de61df5udXz1BCRypCh/zep_quiz_landing_en.webm',
    ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/s1wwr3wK87cudXoAHBQNrxN/5nVkf3wK87h426519772c00bb15dca18613d6d466f7udXyXjBjebqv/zep_quiz_landing_ja.webm',
  },
  mp4: {
    ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/RLha63wK87cudXS0Sj3w5ne/TO2ts3wK8aZb1e317e217103cf53f1b21c143ab0439udXS0U7M67MG/zep_quiz_landing_m.mp4',
    en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/rm0YA3wK87cudXS0Sj2x97m/ghACJ3wK87h916678e26b0b6660baa6c6cae219c196udXS0U83Xsoq/zep_quiz_landing_en_m.mp4',
    ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/qsKVs3wK87dudXS0SibPEtU/NRrOq3wK87hb1e317e217103cf53f1b21c143ab0439udXS0U75w7Wb/zep_quiz_landing_ja_m.mp4',
  },
};
